import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg height="24px" viewBox="0 0 17.837 17.837" width="24px" xmlSpace="preserve" {...props}>
    <path
      d="M16.145 2.571a.7.7 0 0 0-.99 0L6.92 10.804l-4.241-4.27a.7.7 0 0 0-.989 0L.204 8.019a.703.703 0 0 0 0 .99l6.217 6.258a.704.704 0 0 0 .99 0L17.63 5.047a.7.7 0 0 0 0-.994z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgCheckIcon);
export default Memo;
